var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "text-primary mt-base mb-base text-center" }, [
        _vm._v("\n    Herramientas de "),
        _c("strong", [_vm._v(_vm._s(_vm.blogTypeName.toUpperCase()))]),
        _vm._v(" por cada\n    área de negocio.\n  ")
      ]),
      _c(
        "vx-card",
        { staticClass: "mt-base" },
        [
          _vm.categories.length > 0
            ? _c(
                "vs-tabs",
                { attrs: { alignment: "center", "vs-alignment": "fixed" } },
                _vm._l(_vm.categories, function(item) {
                  return _c(
                    "vs-tab",
                    {
                      key: item.id,
                      attrs: {
                        label: item.name,
                        "icon-pack": "feather",
                        icon: item.imageUrl
                      },
                      on: {
                        click: function($event) {
                          return _vm.get(item.id)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-row" },
                        _vm._l(_vm.dataList, function(item) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticClass:
                                "vx-col w-full md:w-1/3 sm:w-full mb-4"
                            },
                            [
                              _c(
                                "vx-card",
                                { staticClass: "text-center cursor-pointer" },
                                [
                                  item.attachmentType == "MP4"
                                    ? _c("iframe", {
                                        attrs: {
                                          width: "350",
                                          height: "370",
                                          src: item.attachementUrl,
                                          frameborder: "0",
                                          allow:
                                            "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                                          allowfullscreen: ""
                                        }
                                      })
                                    : item.attachmentType == "PDF"
                                    ? _c("vs-icon", {
                                        attrs: {
                                          "icon-pack": "material-icons",
                                          icon: "picture_as_pdf",
                                          size: "large",
                                          color: "danger"
                                        }
                                      })
                                    : _c("img", {
                                        staticClass: "mx-auto mb-4",
                                        attrs: {
                                          src: item.attachementUrl,
                                          alt: "graphic",
                                          width: "200"
                                        }
                                      }),
                                  _c("vs-button", {
                                    attrs: {
                                      color: "success",
                                      type: "line",
                                      "icon-pack": "feather",
                                      icon: "icon-download-cloud",
                                      size: "large"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDocument(
                                          "" + item.attachementUrl
                                        )
                                      }
                                    }
                                  }),
                                  _c("h4", { staticClass: "mb-2" }, [
                                    _c("strong", [_vm._v(_vm._s(item.title))])
                                  ]),
                                  _c("div", {
                                    domProps: { innerHTML: _vm._s(item.body) }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "vx-row mt-4" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full text-right" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "rgb(174,174,174)", type: "filled" },
                on: {
                  click: function($event) {
                    return _vm.$router.push("/resources/marketing")
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Return.i18n) ||
                      _vm.resources.Return.name
                  )
                )
              ]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }