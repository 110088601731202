<!-- =========================================================================================
    File Name: KnowledgeBase.vue
    Description: Knowledge Base Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <!-- KNOWLEDGE BASE CARDS  -->
    <h1 class="text-primary mt-base mb-base text-center">
      Herramientas de <strong>{{ blogTypeName.toUpperCase() }}</strong> por cada
      área de negocio.
    </h1>

    <vx-card class="mt-base">
      <vs-tabs
        alignment="center"
        vs-alignment="fixed"
        v-if="categories.length > 0"
      >
        <vs-tab
          v-for="item in categories"
          :key="item.id"
          :label="item.name"
          icon-pack="feather"
          :icon="item.imageUrl"
          @click="get(item.id)"
        >
          <div class="vx-row">
            <div
              class="vx-col w-full md:w-1/3 sm:w-full mb-4"
              v-for="item in dataList"
              :key="item.id"
            >
              <vx-card class="text-center cursor-pointer">
                <!-- <vs-icon
                  v-if="item.attachmentType == 'MP4'"
                  icon-pack="material-icons"
                  icon="movie"
                  size="large"
                ></vs-icon> -->
                <iframe
                  v-if="item.attachmentType == 'MP4'"
                  width="350"
                  height="370"
                  :src="item.attachementUrl"
                  frameborder="0"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <vs-icon
                  v-else-if="item.attachmentType == 'PDF'"
                  icon-pack="material-icons"
                  icon="picture_as_pdf"
                  size="large"
                  color="danger"
                ></vs-icon>
                <img
                  v-else
                  :src="item.attachementUrl"
                  alt="graphic"
                  width="200"
                  class="mx-auto mb-4"
                />
                <vs-button
                  color="success"
                  type="line"
                  icon-pack="feather"
                  icon="icon-download-cloud"
                  size="large"
                  @click="openDocument(`${item.attachementUrl}`)"
                ></vs-button>
                <h4 class="mb-2">
                  <strong>{{ item.title }}</strong>
                </h4>
                <div v-html="item.body"></div>
              </vx-card>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>

    <div class="vx-row mt-4">
      <div class="vx-col w-full text-right">
        <vs-button
          color="rgb(174,174,174)"
          type="filled"
          @click="$router.push('/resources/marketing')"
          >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      dataList: [],
      categories: [],
      blogTypeId: "",
      blogTypeName: "",
      contentTypeId: 9 //Visionbiz
    };
  },
  computed: {},
  async created() {
    this.blogTypeId = this.$route.params.blogTypeId;
    this.blogTypeName = this.$route.params.blogTypeName;

    await this.get(this.contentTypeId);
    await this.getContenType();
  },
  methods: {
    openDocument(url) {
      window.open(url, this.$i18n.t("View"));
    },

    async get(contentType) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Search`,
        data: {
          searchKeys: {
            blogTypeId: this.blogTypeId,
            contentTypeId: contentType
          },
          page: 1,
          rows: 30,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            this.dataList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getContenType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ContentType/GetByType/4/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.categories = [];
          } else {
            this.categories = result.data.filter(
              x => x.id !== 0 && x.type != 3
            );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  }
};
</script>
